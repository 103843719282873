<script>
import Layout from "./layout/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import shiftApproved from "@/views/pages/employee/components/shiftApproved";
import shiftRelease from "@/views/pages/employee/components/shiftRelease";
import shiftRequest from "@/views/pages/employee/components/shiftRequest";
import shiftRejected from "@/views/pages/employee/components/shiftRejected";
import calendar from "@/views/pages/employee/components/calendar";
// import pickReleasedShiftVue from "./components/pickReleasedShift.vue";
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    shiftApproved,
    shiftRelease,
    shiftRequest,
    shiftRejected,
    // calendar,
    // pickReleasedShiftVue,
  },

  data() {
    return {
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],

      released_shifts: [],
      approved_shifts: [],
      pending_shifts: [],
      rejected_shifts: [],
      locations: [],
      location_id: null,
      countsLoading : false,

      weekly_active_shifts: null,
      weekly_bonus_shifts: null,
      weekly_rejected_shifts: null,
      weekly_released_shifts: null,
      weekly_completed_shifts: null,
      weekly_release_applied_shifts: null,
      weekly_vacant_shifts: null,
      loading:false,
    };
  },
  created() {
    this.user = this.$store.state.auth.currentUser;
  },
  mounted() {
    // this.fetchEmployeeLocations();
    this.locations = this.$store.state.auth.currentUser.employee.locations;
    this.location_id = this.locations[0].id;
    this.statsCount()
  },
  methods: {
    statsCount()
    {
      this.loading = true;
      this.countsLoading = true;
      this.$axios.get('dashboard/employee-dashboard-stats/' + this.user.employee.id , {params : {location_id : this.location_id}}).then(response => {
        this.loading = false;
        let data = response.data.payload;
        this.approved_shifts = data.approved_shifts.data;
        this.released_shifts = data.released_shifts.data;
        this.rejected_shifts = data.rejected_shifts.data;
        this.pending_shifts = data.pending_shifts.data;
        this.weekly_completed_shifts = data.weekly_completed_shifts;
        this.weekly_active_shifts = data.weekly_active_shifts;
        this.weekly_rejected_shifts = data.weekly_rejected_shifts;
        this.weekly_release_applied_shifts = data.weekly_release_applied_shifts;
        this.weekly_released_shifts = data.weekly_released_shifts;
        this.weekly_bonus_shifts = data.weekly_bonus_shifts;
        this.weekly_vacant_shifts = data.weekly_vacant_shifts;
        
        })
        .catch(error => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => (this.countsLoading = false));
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" class="poppins fw-normal"  />

     <div class="row align-items-center">
        <div class="col-md-6">
          <router-link to="/" v-if="isManager()" class="d-flex align-items-center">
            <button class="btn primaryColorBg text-white">
              <i class="fa fa-key mx-2"></i>Switch to Manager
            </button>
          </router-link>
        </div>
        <!-- <div class="col-md-6 mb-3 mx-auto me-0"> -->
          <!-- <div class="d-flex align-items-center gap-2"> -->
            <!-- <div class="icon-wrapper mt-3 pt-1"> -->
            <!-- </div> -->
            <!-- <div class="form-field__wrapper w-100"> -->
              <!-- <label>Select Location</label> -->

              <!-- <div class="border input-group rounded shadow">
                <div class="bg-white border-0 input-group-text">
                  <img src="@/assets/images/profile-manager/icon-11.png" width="21" alt="" />
                </div>
                <select v-model="location_id" id="emp_job_type" class="form-select border-0" @change="fetchStats()" >
                  <option :value="location.id" v-for="(location, index) in locations" :key="index">
                    {{ location.name }}
                  </option>
                </select>
              </div> -->
            <!-- </div> -->
          </div>
        <!-- </div> -->
    <!-- </div> -->
   
    <div class="row">
      <div class="col-12">
        <div class="d-grid gap-2 grid_inline">
          <div class="card rounded box-shadow px-3 py-3">
            <router-link to="/employee-profile">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/location_icon.png">
                <h2 class="text-dark f-30 pt-1">{{locations.length}}</h2>
                <p class="text-dark mb-2">Total Locations</p>
                <span class="secondaryColor">Enrolled</span>
              </div>
            </router-link>
          </div>

          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'Completed' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/upcomming_week.png">
                <h2 class="text-dark f-30 pt-1">{{weekly_completed_shifts}}</h2>
                <p class="text-dark mb-2">Completed Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>

          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'Accepted' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/active_shifts_icon.png">
                <h2 class="text-dark f-30 pt-1">{{weekly_active_shifts}}</h2>
                <p class="text-dark mb-2">Active Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>

          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'Rejected' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/upcomming_week.png">
                <h2 class="text-dark f-30 pt-1">{{weekly_rejected_shifts}}</h2>
                <p class="text-dark mb-2">Rejected Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>

          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'ApplyForLeave' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/leaves_apply.png">
                <h2 class="text-dark f-30 pt-1">{{weekly_release_applied_shifts}}</h2>
                <p class="text-dark mb-2">Leaves Applied</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>

          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'Released' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/dropped_shifts.png">
                <h2 class="text-dark f-30 pt-1">{{weekly_released_shifts}}</h2>
                <p class="text-dark mb-2">Dropped Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>

          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'NewShifts' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/bonus_shifts.png">
                <h2 class="text-dark f-30 pt-1">{{weekly_bonus_shifts}}</h2>
                <p class="text-dark mb-2">Bonus Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>

          <div class="card rounded box-shadow px-3 py-3">
            <router-link :to="{ path: '/shift-listing', hash: 'NewShifts' }">
              <div class="innerCard">
                <img src= "@/assets/images/dashboard/open_shifts.png">
                <h2 class="text-dark f-30 pt-1">{{weekly_vacant_shifts}}</h2>
                <p class="text-dark mb-2">Vacant Shifts</p>
                <span class="secondaryColor">This Week</span>
              </div>
            </router-link>
          </div>

        </div>
      </div>
    </div>

    <shiftApproved :loading="loading" :shifts="approved_shifts" />

    <!-- <pickReleasedShiftVue :shifts="released_shifts" /> -->

    <shiftRelease :loading="loading" :shifts ="released_shifts"/>  

    <shiftRequest :loading="loading" :shifts="pending_shifts" />

    <shiftRejected :loading="loading" :shifts="rejected_shifts" />

  </Layout>
</template>
