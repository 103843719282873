<script>
import Layout from "@/views/layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import AlertWidget from "@/components/general/AlertWidget";
/**
 * Add-product component
 */
export default {
  page: {
    title: "Shifts Records",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  // props:['shifts'],
   props : {
    shifts : {
      type: [Object, Array],
      default: () => {
          return []
      }
    },
    next_page_url : {
      tyoe : String
    },
    loading:{
      type: Boolean
    }
  },
  components: {
   AlertWidget
  },
  data() {
    return {
      arearole:null,
    };
  },
  middleware: "authentication",
  
  // methods:{
  //      checkCountWithStatus(){
  //      let i =0;
  //      this.shifts.map((shift)=>{
  //        if(shift.status=='Released'){
  //          i =i+1;
  //        }
  //      })
  //      if(i>0){
  //        return true;
  //      }else{
  //        return false;
  //      }
  //    }

  // }
};
</script>

<template>
    <div>
               <div class="row">
      <div class="col-lg-12">
        <div class="card dashboard__wrapper">
          <div class="card-header bg-white border-bottom p-3 border-top-radius">
                <h2 class="f-20 poppins text-dark mb-0">Released Shifts</h2>
              </div>
          <div class="card-body">
          
            <div class="table-responsive">
              <table class="table mb-0 table-bordered">
                <thead>
                  <tr>
                  
                    <th class="f-14 roboto">Employee Name</th>
                    <th class="f-14 roboto">User Email</th>
                    <th class="f-14 roboto">Contact</th>
                    <th class="f-14 roboto">Selected Role/Position</th>
                    
                    <th class="f-14 roboto">Location</th>
                    <th class="f-14 roboto">Shift From</th>
                    <th class="f-14 roboto">Shift To</th>
                    <!-- <th class="f-14 roboto">Selected Time From</th>
                    <th class="f-14 roboto">Selected Time To</th> -->
                    <th class="f-14 roboto">Reason</th>
                    <th class="f-14 roboto">Status</th>
             
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(shift,index) in shifts" :key="index">
                    <!-- <template v-if="shift.status=='Released'"> -->
                    
                    <td class="f-14 roboto align-middle">{{getSafe(() => shift.employee.user.full_name)}}</td>
                   
              
                    <td class="f-14 roboto align-middle">{{getSafe(() => shift.employee.user.email)}}</td>
                    <td class="f-14 roboto align-middle">{{getSafe(() => shift.employee.user.phone)}}</td>
                    <td class="f-14 roboto align-middle">{{getSafe(() => shift.shift_role.role.name)}}</td>
                    <td class="f-14 roboto align-middle">
                      {{getSafe(() => shift.shift.location.name)}}
                    </td>
                    <!-- <td class="f-14 roboto align-middle">
                     {{DateWithMonthName(shift.shift.from)}}
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{DateWithMonthName(shift.shift.to)}}
                    </td> -->
                    <td class="f-14 roboto align-middle">
                     {{getSafe(() => DateWithMonthName(shift.from))}}
                    </td>
                    <td class="f-14 roboto align-middle">
                      {{getSafe(() => DateWithMonthName(shift.to))}}
                    </td>
                    <td class="f-14 roboto align-middle">
                       {{getSafe(() => shift.action_detail)}}

                    </td>
                    <td class="f-14 roboto align-middle">
                       {{getSafe(() => shift.status)}}

                    </td>
                    <!-- </template> -->
                  </tr>
                  <tr v-if="loading">
                    <td colspan="13">
                      <APILoader
                        :loading="loading"
                        class="align-middle"
                      ></APILoader>
                    </td>
                  </tr>
                  <tr v-if="shifts.length <= 0 && loading === false">
                    <td colspan="10">
                      <AlertWidget class="mt-4" text="No Record Found"></AlertWidget>
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <div v-if="checkCountWithStatus()" class="col-md-2 mx-auto me-0 pt-2">
                    <router-link class="w-100 btn btn-primary gradientColor border-0 radius-30 py-2 fw-bold" to="/shift-listing">
                      See All
                    </router-link> 
                  </div>-->
              <div class="seeMore pt-3 text-center" v-if="next_page_url">
                <a href="javascript:void(0)" @click="$emit('releasedShifts_nextPageUrl');">See More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

              <!-- end .border-->
            </div>
</template>
